<template>
    <main>
        <h1>Sorry, that page does not exist</h1>
        <p>We are unable to complete your request.</p>
    </main>
</template>

<script>
    export default {
        name: "ErrorPage"
    }
</script>

<style scoped>

</style>